<template>
    <div>
    <ul class="text">
        <li v-for="(step, index) in appInstalationStegs" :key="index"  class="step-item">
            {{ $t(step) }}
        </li>
    </ul>

        <div class="screenshot">
            <img src="../../assets/flexIptv/screen-2.png" :alt="$t('faq.flexIptv.step2.goToMenuIcon')" :height="167" class='step-image'>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appInstalationStegs: ['faq.flexIptv.step2.goToMenuIcon', 'faq.flexIptv.step2.addPlaylistRemote', 'faq.flexIptv.step2.clickMenuIcon']
        }
    }
}
</script>

<style scoped>
@import "../../../../components/styles/guides-default-styles.css";
</style>
